<template>
	<div class="main_Div">
		<!-- 头部搜索 -->
		<div class="photoBgVideo" v-if="bannerList.src">
			<video class="bgVideo" :src="bannerList.src" x5-playsinline
				webkit-playsinline="true" playsinline="true" loop muted="muted" autoplay>
			</video>
			<div class="photoSearch">
				<Search :keys="getListParams.key" @searchKeyFun="searchKeyFun" type="2381"></Search>
			</div>
		</div>
		<!-- 分类选择 -->
		<div class="swiperBox">
			<swiper ref="mySwiper" class="swiper" :options="swiperOption">
				<swiper-slide v-for="(item, index) in menuList" :key="index">
					<div class="slideBox">
						<img class="img" :src="item.img" :data-itemid="item.id" alt="" />
						<p>{{ item.title }}</p>
					</div>
				</swiper-slide>
				<div class="swiper-button-prev" slot="button-prev"></div>
				<div class="swiper-button-next" slot="button-next"></div>
			</swiper>
		</div>
		<!-- 图库列表 -->
		<div class="photoTabBox">
			<template v-for="(item, index) in photoTab">
				<div class="photoTab" @click="changeTab(item.type)" :class="{activeTab: activeTab == item.type}"
					:key="index">
					{{item.name}}
				</div>
			</template>
		</div>

		<div class="ListBox">
			<PhotoList ref="list" :loading="loading" :colNum="activeTab" :listStatus="listStatus"
				:photoListObj="photoListObj" @nextPage="nextPage">
			</PhotoList>
		</div>
	</div>
</template>

<script>
	import Search from "@/components/Search";
	import PhotoList from "./componts/PhotoList.vue";
	import {
		getPictureCateList,
		getBanner
	} from "@/Api";
	import {
		mapGetters
	} from "vuex";
	import {
		getToken
	} from "../../utils/auth";
	import {
		Swiper,
		SwiperSlide
	} from 'vue-awesome-swiper'
	import 'swiper/css/swiper.css'
	export default {
		name: "PhotoTypeIndex",
		data() {
			let _this = this;
			return {
				bannerList: {},
				swiperOption: {
					// loop: true,
					slidesPerView: 10,
					// centeredSlides: true, // 重点：让active slide居中
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					},
					on: {
						init() {
							this.setTranslate(0);
						},
						transitionStart() { // 开始translate之前触发
							// 如果是第一张
							// if (this.activeIndex == 0) {
							// 	console.log(123111111);
							// 	this.setTranslate(0); // 因为左边会空出一张图的距离，所以设置位移为0
							// }
						},
						click(e) {
							// 使用e.target事件代理获取点击的元素，通过data语法获取元素的属性值
							let id = e.target.dataset.itemid;
							// 这里的this指向轮播,所以我在外边声明了_this用来代表vue实例
							_this.changeMenu(id); // 跳转到详情页
						}
					}
				},
				photoTab: [{
					name: '推荐图片',
					type: 1,
				}, {
					name: '精品图集',
					type: 2,
				}],
				activeTab: 1,
				menuList: [],

				loading: false,
				getListParams: {
					key: "",
					is_album: 0,
					// order: 0,
					page: 0,
					// fid: 2381,
					per_page: 30,
					token: getToken(),
				},
				listStatus: {
					isData: true,
					isNull: false,
				},
			};
		},
		components: {
			Search,
			PhotoList,
			Swiper,
			SwiperSlide
		},
		created() {
			this.getListParams.page = 1;
			getPictureCateList({
				fid: 2381
			}).then((res) => {
				this.menuList = res.data;
			});
			if (this.$route.query.search) {
				this.getListParams.key = this.$route.query.search;
				this.getListParams.page = 1;
				this.$refs.list && this.$refs.list.clear();
			}
			getBanner({
				position: 2564,
				cate: 2566,
			}).then((res) => {
				this.bannerList = res.data && res.data[0];
			});
		},
		methods: {
			changeTab(type) {
				this.$refs.list && this.$refs.list.clear();
				this.activeTab = type;
				this.getListParams.is_album = type == 1 ? 0 : 1;
				this.getListParams.page = 1;
			},
			changeMenu(id) {
				if (!id) return
				const {
					href
				} = this.$router.resolve({
					path: "/photo/index",
					query: {
						child1: id
					}
				});
				window.open(href, '_blank')
			},
			//  搜索
			searchKeyFun(keys) {
				this.getListParams.page = 1;
				this.getListParams.key = keys;
				this.$refs.list && this.$refs.list.clear();
			},
			//下一页
			nextPage() {
				this.getListParams.page += 1;
			},
		},
		computed: {
			...mapGetters(["photoListObj"]),
		},
		watch: {
			getListParams: {
				handler: function() {
					this.loading = true;
					(this.listStatus.isData = true), (this.listStatus.isNull = false);
					this.$store.dispatch("app/getPhoto", this.getListParams).then((res) => {
						this.loading = false;
						const {
							data
						} = res;
						if (data.total == 0) {
							this.$refs.list && this.$refs.list.clear();
							this.listStatus.isNull = true;
						} else if (data.current_page == data.last_page) {
							this.listStatus.isData = false;
						}
					});
				},
				deep: true,
			},
		},
	};
</script>

<style scoped>
	.main_Div {
		background-color: #ffffff;
	}

	/* 头部搜索开始 */
	.photoBgVideo {
		position: relative;
		width: 100%;
	}

	.bgVideo {
		display: block;
		width: 100%;
		min-width: 14rem;
	}

	.photoSearch {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	/* 分类选择开始 */
	.swiperBox {
		width: 100%;
		height: 1.4rem;
		padding: 0 1rem;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.swiper {
		padding: 0 0.5rem;
		--swiper-navigation-color: rgba(56, 56, 56, 1);
		/* 单独设置按钮颜色 */
		--swiper-navigation-size: 0.25rem;
		/* 设置按钮大小 */
	}

	.swiper-button-prev {
		width: 0.5rem;
		height: 0.9rem;
		margin-top: 0;
		background-color: #ffffff;
		position: absolute;
		top: 0;
		left: 0;
		box-shadow: 4px 0px 5px 3px rgb(255 255 255);
	}

	.swiper-button-next {
		width: 0.5rem;
		height: 0.9rem;
		margin-top: 0;
		background-color: #ffffff;
		position: absolute;
		top: 0;
		right: 0;
		box-shadow: -4px 0px 5px 3px rgb(255 255 255);
	}

	.swiper {
		width: 100%;
		height: 0.9rem;
	}

	.swiper-slide {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	.slideBox {
		position: relative;
		width: 1.52rem;
		height: 0.9rem;
		border-radius: 0.1rem;
		overflow: hidden;
		cursor: pointer;
	}

	.slideBox img {
		width: 1.52rem;
		height: 0.9rem;
		border-radius: 0.1rem;
		object-fit: cover;
		transition: all 0.6s;
		filter: blur(0px);
	}

	.slideBox:hover img {
		filter: blur(2px);
		transform: scale(1.2);
	}

	.slideBox p {
		pointer-events: none;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		border-radius: 0.1rem;
		background-color: rgba(0, 0, 0, 0.3);
		font-size: 0.18rem;
		font-weight: 500;
		line-height: 0.9rem;
		color: rgba(255, 255, 255, 1);
		text-align: center;
		cursor: pointer;
	}

	/* 图库列表开始 */
	.photoTabBox {
		width: 100%;
		height: 1rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.photoTab {
		width: 2rem;
		height: 0.5rem;
		line-height: 0.5rem;
		text-align: center;
		font-size: 0.32rem;
		font-weight: 500;
		color: rgba(4, 4, 4, 1);
		cursor: pointer;
		transition: all 0.6s;
	}

	.activeTab {
		font-weight: bold;
	}


	::v-deep .el-dialog {
		width: 7.1rem !important;
	}

	::v-deep .el-dialog__body {
		padding: 0 0.2rem 0.3rem;
	}

	::v-deep .el-dialog__headerbtn {
		top: 0.05rem;
	}
</style>
