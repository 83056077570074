<!--各主页搜索组件-->
<template>
  <div class="w100">
    <div class="dfc searchBox m_c">
      <el-input
        type="text"
        v-model="searchKey"
        :placeholder="`请输入关键词，查找${searchName}`"
        @keyup.enter.native="searchKeyF"
        clearable
        @clear="searchKeyF"
      />
      <p v-if="type == 4"></p>

      <div
        class="dfc c_p buttonBg searchButton"
        :class="[type == 4 ? 'buttonBg1' : 'buttonBg']"
        @click="searchKeyF"
      >
        <img
          v-if="type == 4"
          src="@/assets/image/icon/search-icon1.png"
          alt=""
        />
        <img v-else src="@/assets/image/icon/search-icon.png" alt="" />
      </div>
    </div>
    <div style="width: 8rem" v-if="type == '4'" class="m_c mt10">
      <div class="df_Yc">
        <div class="df_Yc">
          <img
            class="fire-icon"
            src="@/assets/image/icon/fire-icon2.png"
            alt=""
          />
          <span class="f14 f_b c_ff mr10">热搜:</span>
        </div>
        <div class="df_Yc">
          <div
            class="f14 f_b c_ff mr20 c_p tag"
            v-for="(item, index) in tags"
            @click="searchTag(item)"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
		<div v-else-if="hotTags&&hotTags.length" class="hot m_c">
			<ul class="hotList">
				<li class="hotLi">热门搜索：</li>
				<template v-if="type == '1068'">
					<li class="hotLi c_p" v-for="item in hotTags" @click="searchTag(item)" :key="item.id">
						{{item}}
					</li>
				</template>
				<template v-else>
					<li class="hotLi c_p" v-for="item in hotTags" @click="searchGo(item.url)" :key="item.id">
						{{item.title}}
					</li>
				</template>
			</ul>
		</div>
  </div>
</template>

<script>
import {getRecommendHotWords, hotSearch} from "../Api.js"
export default {
  name: "Search",
  props: ["type", "keys", "tags"],
  data() {
    return {
      searchName: "",
      searchKey: "",
			fid: "",
			hotTags: []
    };
  },
  created() {
    if (this.type == 1) {
			this.fid = 6;
      this.searchName = "课程";
    } else if (this.type == 2) {
			this.fid = 7;
      this.searchName = "素材";	// 资料库
    } else if (this.type == 3) {
			this.fid = 561;
      this.searchName = "SU模型";
    } else if (this.type == 5) {
			this.fid = 1196;
      this.searchName = "PS素材";
    } else if (this.type == 1393) {
			this.fid = 1393;
      this.searchName = "3D模型";
    } else if (this.type == 1394) {
			this.fid = 1394;
      this.searchName = "方案文本";
    } else if (this.type == 2205) {
			this.fid = 2205;
      this.searchName = "贴图";
    } else if (this.type == 2381) {
			this.fid = 2381;
      this.searchName = "图库";
    }
		this.getHot()
    if (this.$route.query.search) {
      this.searchKey = this.$route.query.search;
    }
  },
  methods: {
		getHot() {
			if (!this.fid) return
			if (this.fid == 1068) {
				//获取热门标签
				hotSearch({ fid: 1068 })
					.then((res) => {
						this.hotTags = res.data;
					});
			} else{
				getRecommendHotWords({ fid: this.fid})
					.then((res) => {
						this.hotTags = res.data
					})
			}
		},
    searchKeyF() {
      this.$emit("searchKeyFun", this.searchKey);
      const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
      query.search = this.searchKey; //改变参数
      this.$router.push({ path: this.$route.path, query });
    },
    searchTag(tag) {
      this.searchKey = tag;
      this.searchKeyF();
    },
		searchGo(http) {
			window.open(http, '_blank')
		}
  },
  watch: {
    keys(val) {
      this.searchKey = val;
    },
  },
};
</script>

<style scoped>
.hot {
	width: 8rem;
}
.hotList {
	display: flex;
	padding: 0 0.08rem;
}
.hotLi {
	height: 0.26rem;
	padding: 0.04rem 0.12rem;
	margin: 0.08rem;
	border-radius: 13rem;
	background-color: #fff;
	color: rgba(64, 64, 64, 1);
	font-size: 0.12rem;
	font-weight: 500;
	letter-spacing: 0px;
	display: flex;
	align-items: center;
}
.hotLi:nth-child(1) {
	color: #fff;
	background: none;
	padding: 0.04rem 0;
	margin: 0.08rem 0;
	margin-left: -0.08rem;
	text-shadow: 0px 0px 3px black;
}
.hotLi:nth-child(2) {
	color: #fff;
	background: rgba(255, 141, 26, 1);
}
.searchBox >>> .el-input__inner {
  border: 0;
}
.fire-icon {
  width: 0.12rem;
  height: 0.16rem;
  margin-right: 0.02rem;
}
.tag:hover {
  color: #f46600;
}
.searchBox {
  width: 8rem;
  height: 0.6rem;
  border-radius: 0.04rem;
  background: #ffffff;
  overflow: hidden;
	box-shadow: 0 0 7px 0px rgba(0, 0, 0, 0.15);
}

.searchBox input {
  width: 6.96rem;
  height: 0.6rem;
  padding-left: 0.2rem;
}

.searchBox input::-webkit-input-placeholder {
  color: #bfbfbf;
  font-size: 0.14rem;
}

.searchButton {
  width: 1.54rem;
  height: 0.6rem;
}

.buttonBg {
  background: #f46600;
}

.buttonBg1 {
  background: #fff;
}
.searchBox p {
  width: 0px;
  height: 0.3rem;
  border: 1px solid #040404;
  opacity: 1;
}

.searchButton img {
  width: 0.3rem;
  height: 0.3rem;
}
</style>