<template>
	<div :ref="refName">
		<div class="mt30" v-if="loading || list.length">
			<el-skeleton 
				class="vue-waterfall"
				:loading="loading && !list.length && !albumList"
				animated
				:count="colNum == '2' ? 4 : 5"
				>
				<template slot="template">
					<div class="commonItemHover" v-if="colNum == '2'" style="width: 25%;display: inline-block;">
						<div class="cell-item-union">
							<router-link target="_blank" :to="{ path: '/chartlet/album', query: {detailId: 1}}" >
								<div class="item-union-img">
									<div class="union-img">
										<el-skeleton-item  class="image flex-cc" variant="image" />
									</div>
									<div class="union-img union-img-right">
										<el-skeleton-item style="display: flex;" class="image flex-cc" variant="image" />
										<el-skeleton-item style="display: flex;" class="image flex-cc" variant="image" />
									</div>
								</div>
							</router-link>
						</div>
						<el-skeleton-item style="width: calc(100% - 0.24rem);height: 0.36rem;" class="item-union-name txt-over" variant="text" />
	
					</div>
					<div v-else style="width: 20%;display: inline-block;">
						<div class="cell-item " style="box-shadow: none;">
							<div class="itemBox">
								<el-skeleton-item class="skeleton-item-higth" variant="image" />
							</div>
						</div>
					</div>
				</template>
				<waterfall :col="colNum == '2'||albumList ? 4 : 5" :data="list"  :height="albumList?'2.44rem':'100%'" :gutterWidth="0" @scroll="scroll" @finish="finishFn">
					<template v-if="colNum == '2'">
						<div class="commonItemHover" style="margin-bottom: 0.32rem;" v-for="(item, index) in list" :key="index">
							<div class="cell-item-union">
								<router-link target="_blank" :to="{ path: '/photo/album', query: {detailId: item.id}}">
									<div class="item-union-img">
										<div class="union-img">
											<img class="image" oncontextmenu="return false" v-if="item&&item.pic_link&&item.pic_link[0]" :src="item&&item.pic_link&&item.pic_link[0]" alt="加载错误" />
										</div>
										<div class="union-img union-img-right">
											<img class="image" oncontextmenu="return false" v-if="item&&item.pic_link&&item.pic_link[1]" :src="item&&item.pic_link&&item.pic_link[1]" alt="加载错误" />
											<img class="image" oncontextmenu="return false" v-if="item&&item.pic_link&&item.pic_link[2]" :src="item&&item.pic_link&&item.pic_link[2]" alt="加载错误" />
											<div v-if="item&&item.pic_num" class="union-num">+{{item&&item.pic_num}}</div>
										</div>
									</div>
								</router-link>
							</div>
							<div class="item-union-name txt-over">
								{{item&&item.title || ''}}
							</div>
						</div>
					</template>
					<template v-else>
						<div :class="{'commonItemHover': !albumList}" v-for="(item, index) in list" :key="index">
							<div class="cell-item" :class="{'album-item': albumList}">
								<router-link target="_blank" :to="{ path: '/photo/detail', query: {detailId: item.id}}">
									<div class="itemBox">
										<img oncontextmenu="return false" v-if="item&&item.img || item.pic_str" :src="item&&item.img || item.pic_str" alt="加载错误" />
									</div>
								</router-link>
								<div v-if="!albumList" class="item-dow" @click.stop="download(item.id)">
									<i class="iconfont">&#xe74d;</i>
								</div>
							</div>
						</div>
					</template>
				</waterfall>
				<div style="height: 40px;" v-if="!albumList">
					<img class="loadImg" :class="{ loadImgs: !loading }" src="@/assets/image/loading.gif" alt="">
					<p class="loadText" :class="{ loadTexts: !loading && !listStatus.isData }">
						没有更多了...
					</p>
				</div>
			</el-skeleton>
		</div>
		<div v-else>
			<el-empty description="没有内容哦"></el-empty>
		</div>
	</div>
</template>
<script>
import { checkDownTimes } from "@/Api";
import { getToken } from "@/utils/auth";
export default {
  name: "PhotoList",
	props: {
		photoListObj: {
			type: [Array, Object],
		},
		colNum: {
			type: [Number, String],
			default: 1
		},
		loading: {
			type: Boolean,
			default: false
		},
		albumList: {
			type: Boolean,
			default: false
		},
		listStatus: {
			type: Object,
			default: () => {
				return {
					isData: true,	// 判断是否有下一页
					isNull: false	// 判断是否为空
				}
			}
		},
		refName: {
			type: String,
			default: 'box'
		},
	},
  data() {
    return {
      list: [],
			finish: false
    };
  },
  computed: {},
	methods: {
		clear() {
			this.list = [];
		},
		loadmore() {
			// console.log('index', index);
			this.$emit("nextPage");
		},
		scroll(e) {
			if(e.diff < 300&&this.finish) {
				this.finish=false;
				if (this.listStatus.isNull || !this.listStatus.isData) return;
				this.$emit("nextPage");
			}
		},
		finishFn() {
			this.finish = true;
		},
		download(id) {
			if(!getToken()){
				this.$login()
				return
			}
			
			checkDownTimes({
				token: getToken(),
				id: id
			}).then((res) => {
				if (res.data && res.data.error == 0) {
					const downloadUrl =
						this.$ROOT_DIR +
						"/web/Photo/down?id=" +
						id +
						"&type=2&token=" +
						getToken();
					let link = document.createElement("a");
					link.style.display = "none";
					link.href = downloadUrl;
					document.body.appendChild(link);
					link.click();
				} else if (res.data && res.data.error == -1) {
					this.$tooltipPop().show(1, res.data.msg)
				} else if (res.data && res.data.error == -2) {
					this.$tooltipPop().show(2, res.data.msg)
				} else {
					this.$tooltipPop().show(0, res.data.msg)
				}
			}).catch((err) => {
				console.log(err);
			});
		},
		pushData() {
			if (this.photoListObj&&this.photoListObj.data && this.photoListObj.current_page > 1) {
				this.list = this.list.concat(this.photoListObj.data);
			} else if (this.photoListObj.data && this.photoListObj.data.length){
				this.list = this.photoListObj.data
				this.$waterfall.forceUpdate() 
			}
		},
		fnThrottle (method, delay, duration) {
			var that = this;
			var timer = this.timer;
			var begin = new Date().getTime();
			return function(){
				var context = that;
				var args = arguments;
				var current = new Date().getTime();
				clearTimeout(timer);
				if(current-begin>=duration){
					method.apply(context,args);
					begin=current;
				}else{
					that.timer=setTimeout(function(){
						method.apply(context,args);
					}, delay);
				}
			}
		},
		handleScroll() {
			const scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop;
			const windowHeight =
				document.documentElement.clientHeight || document.body.clientHeight;
			const offsetTop = this.$refs[this.refName].offsetHeight;
			if (scrollTop + windowHeight >= offsetTop && !this.loading && !this.albumList) {
				// console.log(this.listStatus.isNull, !this.listStatus.isData,);
				//到了这个就可以进行业务逻辑加载后台数据了
				if (this.listStatus.isNull || !this.listStatus.isData) return;
				this.fnThrottle(this.loadmore, 200, 150)()
			}
		},
		jump(url, item) {
			const {href} = this.$router.resolve({ path: url, query:{detailId: item.id, name: item.title}});
			window.open(href, '_blank')
		}
	},
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
		photoListObj: {
			handler: function () {
				this.pushData();
			},
			deep: true,
		},
  },
};
</script>
<style scoped>
* {
  margin: 0;
}
.loadImgs {
	height: 0;
}
.loadText {
	height: 0;
	line-height: 40px;
	overflow: hidden;
	text-align: center;
	transition: 0.5s;
	color: black;
}
.loadTexts.loadText {
	height: auto;
	display: block;
}
.loadImg {
	display: block;
	margin: 0 auto;
	color: black;
  transition: 0.5s;
	text-align: center;
}
.cell-item {
	overflow: hidden;
	transition: all 0.3s ease;
	cursor: pointer;
	position: relative;
	display: block;
	border-radius: 0.08rem;
	margin: 0 0.24rem 0.24rem 0;
	box-shadow: 0 0.02rem 0.08rem 0 hsl(0deg 0% 68% / 32%);;
}
.album-item {
	margin: 0 0.03rem 0.02rem 0;
}
.skeleton-item-higth {
	border-radius: 0.08rem;
}
.el-skeleton>div:nth-child(2n+1) .skeleton-item-higth {
	height: 5rem;
}
.el-skeleton>div:nth-child(2n) .skeleton-item-higth {
	height: 3.6rem;
	margin-bottom: 1.4rem;
}
.cell-item img {
	display: block;
	width: 100%;
	border-radius: 0.1rem;
}

.album-item img {
	border-radius: 0.04rem;
}
.cell-item:hover{
	transform: translateY(-6px);
}
.item-dow {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: 0.1rem;
  right: 0.1rem;
  font-size: 0.22rem;
  padding: 0.07rem 0.15rem;
  background: #fcfcfc;
  border-radius: 0.04rem;
  transition: 0.5s;
}
.cell-item:hover .item-dow {
	visibility: visible;
	opacity: 1;
}
.cell-item:hover .collect {
	visibility: visible;
	opacity: 1;
	z-index: 2;
}
.collect i {
	font-size: 0.18rem;
	color: #333333;
}
.collect p {
	font-size: 0.14rem;
	color: #333333;
	margin-right: 0.2rem;
}
.cell-item-union {
	position: relative;
	cursor: pointer;
	width: 100%;
	padding-bottom: 75%;
	margin-bottom: 0.1rem;
}
.item-union-img {
	position: absolute;
	top: 0;
	right: 0.12rem;
	bottom: 0;
	left: 0.12rem;
	overflow: hidden;
	background-color: #fff;
	border-radius: 0.04rem;
	display: flex;
}
.union-img {
	flex: 1;
	width: 100%;
	height: 100%;
}
.union-img-left {
	padding-right: 0.04rem;
}
.union-img-right {
	padding-left: 0.04rem;
	position: relative;
}
.union-num {
	position: absolute;
	top: calc(50% + 0.04rem);
	left: 0.04rem;
	width: 100%;
	height: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 0.36rem;
	font-weight: 700;
	color: rgba(255, 255, 255, 1);
	background-color: rgba(0, 0, 0, 0.2);
}
.union-img>.image{
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.union-img-right>.image{
	width: 100%;
	height: 50%;
	margin-top: 0.04rem;
	display: block;
	filter: blur(2px);
}
.union-img-right>.image:nth-child(1){
	filter: none;
	margin-top: 0;
	margin-bottom: 0.04rem;
}
.item-union-name {
	margin: 0.12rem;
	line-height: 0.36rem;
	font-size: 0.24rem;
	font-weight: 500;
	color: rgba(0, 0, 0, 1);
}
.vue-waterfall {
  width: calc(100% + 0.12rem);
	padding-top: 6px;
	/* margin: 0 -0.06rem; */
	overflow-x: hidden;
}
.vue-waterfall::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.vue-waterfall::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 3px;
}
</style>